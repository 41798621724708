"use client";

import { ThemeContextProvider } from "@/context/themeContext";
import { SessionProvider } from "next-auth/react";

interface ProvidersProps {
  children: React.ReactNode;
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <ThemeContextProvider>
      <SessionProvider>{children}</SessionProvider>
    </ThemeContextProvider>
  );
}
