'use client';

import React from "react";
import { appPages, componentsPages } from "@/config/pages.config";
import { useRouter } from "next/navigation";
import Aside, {
  AsideBody,
  AsideFooter,
  AsideHead,
} from "../../../components/layouts/Aside/Aside";
import LogoAndAsideTogglePart from "./_parts/LogoAndAsideToggle.part";
import DarkModeSwitcherPart from "./_parts/DarkModeSwitcher.part";
import Nav, {
  NavButton,
  NavCollapse,
  NavItem,
  NavSeparator,
  NavTitle,
  NavUser,
} from "../../../components/layouts/Navigation/Nav";
import Badge from "../../../components/ui/Badge";
import UserTemplate from "../User/User.template";
import usersDb from "../../../mocks/db/users.db";

const DefaultAsideTemplate = () => {
  // const router = useRouter();

  return (
    <Aside>
      <AsideHead>
        <LogoAndAsideTogglePart />
      </AsideHead>
      <AsideBody>
        <Nav>
          <NavItem {...appPages.chatAppPages} />
          {/* <NavItem {...appPages.parcelsPage.subPages.listPage} />
          <NavItem {...appPages.warehousesPage.subPages.listPage} /> */}
        </Nav>
      </AsideBody>
      {/* <AsideFooter> */}
        {/* <UserTemplate /> */}
        {/* <DarkModeSwitcherPart /> */}
      {/* </AsideFooter> */}
    </Aside>
  );
};

export default DefaultAsideTemplate;
